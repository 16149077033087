//Control SideMenu
function controlSideMenu() {
  //変数設定
  let ww = window.innerWidth;
  let currentWidth = window.innerWidth;
  let breakpoint = 1024;

  //発火
  toggleAction();
  dropDownAction(ww);

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    currentWidth = window.innerWidth; // 横幅を更新
    $('[data-js="dropDown"] > a').off();//ドロップダウン初期化
    dropDownAction(currentWidth);
  });


  //トグル制御
  function toggleAction() {
    $('#toggle').on('click',function() {
      if($(this).hasClass('is-toggleActive')) {
        //閉じる
        $('html').removeClass('open');
        $(this).removeClass('is-toggleActive');
        $('#gnavi').removeClass('is-navOpen');
      } else {
        //開く
        $('html').addClass('open');
        $(this).addClass('is-toggleActive');
        $('#gnavi').addClass('is-navOpen');
      }
    });
  }

  //ドロップダウン制御
  function dropDownAction(width) {
    $('[data-js="dropDown"] > a').on('click', function(e) {
      let clickDropdown = $(this).siblings('.l-dropDown');
      e.preventDefault();
      if( width >= breakpoint ) {
        //PC動作
        clickDropdown.removeAttr('style');//style属性削除
        if (clickDropdown.hasClass('isDropdownOpen')) {
          clickDropdown.removeClass('isDropdownOpen');//閉じる
        } else {
          $('[data-js="dropDown"] > a').siblings('.l-dropDown').removeClass('isDropdownOpen');
          clickDropdown.addClass('isDropdownOpen');//開く
        }
      } else {
        //タブレット以下動作
        if (clickDropdown.hasClass('isDropdownOpen')) {
          clickDropdown.removeClass('isDropdownOpen');//閉じる
          clickDropdown.slideUp();
        } else {
          clickDropdown.addClass('isDropdownOpen');//開く
          clickDropdown.slideDown();
        }
      }
    });
  }
}
